import { Form, Formik } from "formik";
import { HTTPError } from "ky";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import * as Yup from "yup";
import { AnyObject } from "yup/lib/types";
import apiClient from "../api/apiClient";
import Button from "../components/Button";
import InputField from "../components/InputField";
import LandingCard from "../components/LandingCard";
import LandingRecommendedCard from "../components/LandingRecommendedCard";
import MQView from "../components/MQView";
import AlertContext from "../components/context/AlertContext";
import ModalContext from "../components/context/ModalContext";
import MainLayout from "../components/layouts/MainLayout";
import LoginFormModal from "../components/modal/LoginFormModal";
import useUserStore from "../lib/hooks/useUserStore";
import { useViewport } from "../lib/hooks/useViewport";
import IconLandingCamera from "../svg/ic-landing-camera.svg";
import IconLandingCart from "../svg/ic-landing-cart.svg";
import IconLandingSlider from "../svg/ic-landing-slider.svg";
import CustomNextPage from "../types/CustomNextPage";
//import Image from "next/image";

const Index: CustomNextPage = () => {
  const { isMobileView } = useViewport();
  const alert = useContext(AlertContext);
  const modalContext = useContext(ModalContext);
  const { t: translate } = useTranslation("index");
  const router = useRouter();
  const { setAccessToken } = useUserStore.getState();
  const Scheme = Yup.object().shape({
    email: Yup.string().required(translate("common:landing-page.an-email-is-required") ?? "An email is required."),
    password: Yup.string()
      .required(translate("common:landing-page.a-password-is-required") ?? "A password is required.")
      .min(8),
  });
  useEffect(() => {
    const savedLocale = localStorage.getItem("locale");
    if (isMobileView) {
      if (savedLocale === "hu") {
        router.push("/hu/home");
      } else {
        router.push("/home");
      }
    }
  }, [isMobileView, router]);

  return (
    <>
      <MQView query=">sm">
        <header className="relative">
          <div className="absolute inset-0 bg-[url('/landing-bg.png')] bg-t bg-cover bg-center "></div>
          <div className="absolute h-full landing-shadow w-full inset-0 bg-black bg-opacity-25"></div>
          <div className="flex items-center justify-center w-full h-screen">
            <div className="w-1/2 flex flex-col items-center">
              <h1 className="select-none text-center drop-shadow-[0_0px_5px_rgba(0,0,0,0.5)] shadow-black">{translate("hero.headline")}</h1>
              <div className="flex gap-4 items-center">
                <Button
                  size="large"
                  className="mt-6 w-60 bg-white text-black hover:bg-black active:bg-white active:text-black"
                  onClick={() => {
                    modalContext.show(LoginFormModal, {
                      normalSignup: true,
                      tab: 1,
                    });
                  }}
                >
                  {translate("common:sign-in")}
                </Button>
                <Link href="/home">
                  <Button size="large" className="mt-6 w-60">
                    {translate("common:m-layout.explore-fitters")}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="flex flex-col items-center w-full">
          <h2 className="pt-16 text-xl">{translate("clashes.epic-clashes")}</h2>
          <p className="text-lg text-center max-w-5xl">{translate("clashes.clashes-description")}</p>
          <div className="flex pt-10 gap-5">
            <LandingRecommendedCard
              title={translate("clashes.rugby")}
              slug="magyar-rogbi-szovetseg"
              coverImg="/rugby.png"
              description={translate("clashes.rugby-description")}
            />
            <LandingRecommendedCard
              title={translate("clashes.box-k1-and-mma")}
              slug="kiliti"
              coverImg="/kick-box.png"
              description={translate("clashes.box-k1-and-mma-description")}
            />
          </div>
          <h2 className="my-12 pt-12">{translate("how-to-start.section-title")}</h2>
          <div className="flex w-full justify-evenly mb-36">
            <LandingCard
              title={translate("how-to-start.titles.create-your-account")}
              icon={<IconLandingCamera className="fill-primary w-10" />}
              description={translate("how-to-start.descriptions.create-your-account")}
              button={
                <Button
                  size="min"
                  className="mt-12 w-60"
                  onClick={() => {
                    modalContext.show(LoginFormModal, { normalSignup: true });
                  }}
                >
                  {translate("common:sign-up")}
                </Button>
              }
            />
            <LandingCard
              title={translate("how-to-start.titles.add-payment-method")}
              icon={<IconLandingCart className="fill-primary w-10" />}
              description={translate("how-to-start.descriptions.add-payment-method")}
            />
            <LandingCard
              title={translate("how-to-start.titles.start-watching-sport")}
              icon={<IconLandingSlider className="fill-primary w-10" />}
              description={translate("how-to-start.descriptions.start-watching-sport")}
            />
          </div>
        </div>
        <div className="flex flex-col items-center w-full">
          <h2 className="mb-12">{translate("available.section-title")}</h2>
          <div className="flex w-full justify-evenly mb-24">
            <LandingCard title={translate("available.titles.tv")} description={translate("available.descriptions.tv")} centeredDescription={true} />
            <LandingCard
              title={translate("available.titles.computer")}
              description={translate("available.descriptions.computer")}
              centeredDescription={true}
            />
            <LandingCard
              title={translate("available.titles.mobile-and-tablet")}
              description={translate("available.descriptions.mobile-and-tablet")}
              centeredDescription={true}
            />
          </div>
        </div>
        <div className="hidden md:flex items-center justify-center w-full pb-24">
          <div className="flex gap-8">
            <a
              className="w-56 h-16 rounded-lg hover:scale-105 transition-transform relative"
              href="https://play.google.com/store/apps/details?id=com.sportinlive.android"
            >
              <img className="" src="/googleandroid-download.png" alt="" width={168} height={48} />
            </a>
            <a
              className=" w-56 h-16 rounded-lg hover:scale-105 transition-transform relative"
              href="https://apps.apple.com/hu/app/sportin-live/id6465792537"
            >
              <img className="" src="/appletv-download.png" alt="" width={168} height={48} />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center w-full py-36 bg-white">
          <h2 className="mb-12 text-black">{translate("for-trainers.section-title")}</h2>
          <div className="flex flex-row gap-16 align-middle">
            <div>
              <p className="text-black text-lg whitespace-pre">{translate("for-trainers.section-description")}</p>
              <div className="flex flex-row justify-between gap-8 mt-8">
                <div className="flex gap-4">
                  <IconLandingCamera className="w-10 fill-black" />
                  <h3 className="select-none leading-tight text-lg font-medium whitespace-pre text-black">
                    {translate("for-trainers.livestream-your-sport")}
                  </h3>
                </div>
                <div className="flex gap-4">
                  <IconLandingCart className="w-10 fill-black" />
                  <h3 className="select-none leading-tight text-lg font-medium whitespace-pre text-black">
                    {translate("for-trainers.receive-payment")}
                  </h3>
                </div>
                <div className="flex gap-4">
                  <IconLandingSlider className="w-10 fill-black" />
                  <h3 className="select-none leading-tight text-lg font-medium whitespace-pre text-black">
                    {translate("for-trainers.manage-content")}
                  </h3>
                </div>
              </div>
            </div>
            <div className="flex-col flex justify-center align-middle gap-4 ">
              <Button
                size="min"
                className="w-60"
                onClick={() => {
                  modalContext.show(LoginFormModal, {});
                }}
              >
                {translate("common:streamer-sign-up")}
              </Button>
              <Link href="/startstreaming" passHref>
                <Button variant="outlined" size="min">
                  {translate("common:learn-more")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </MQView>
      {/* Mobile view */}
      <MQView query="<sm">
        <div className="flex items-center w-full h-full">
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Scheme}
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => {
              apiClient
                .post("login", { json: values })
                .json()
                .then((res: any) => {
                  if (res.token) {
                    setAccessToken(res.token);
                  }
                  if (res.redirectTo) {
                    router.push(res.redirectTo);
                  }
                })
                .catch(async (err: HTTPError) => {
                  const response = await err.response.json();
                  alert?.error(`${translate("common:landing-page.failed-to-sign-in")} (${response.error.message})`);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form className="w-full">
                <div className="space-y-6 mb-12">
                  <p className="text-center">{translate("common:landing-page.login-for-trainers")}</p>
                  <InputField name="email" type="email" placeholder={translate("common:landing-page.email") ?? "E-mail"} />
                  <div className="space-y-3">
                    <InputField name="password" type="password" placeholder={translate("common:landing-page.password") ?? "Password"} />
                    <Button
                      variant="text"
                      size="min"
                      type="button"
                      loading={isSubmitting}
                      onClick={() =>
                        modalContext.show(LoginFormModal, {
                          resetPasswordForm: true,
                        })
                      }
                    >
                      {translate("common:landing-page.forgot-password")}
                    </Button>
                  </div>
                  <Button size="full" type="submit">
                    Login
                  </Button>
                </div>
                <div className="flex flex-col space-y-2 border-t border-b py-3 items-center">
                  <h3>{translate("common:landing-page.don-t-have-a-streamer-account-yet")}</h3>
                  <Button size="min" variant="text" type="button" onClick={() => modalContext.show(LoginFormModal, {})}>
                    {translate("common:landing-page.sign-up-for-fitters")}
                  </Button>
                </div>
                <div className="flex flex-col items-center mt-3">
                  <Button size="min" variant="text" type="button" onClick={() => router.push("/home")}>
                    {translate("common:landing-page.login-and-sign-up-for-viewers")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </MQView>
    </>
  );
};
export async function getStaticProps({ locale }: AnyObject) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["index", "common"])),
    },
  };
}
Index.guard = "anonymous";
Index.layout = MainLayout;
export default Index;
